@tailwind base;
@tailwind components;
@tailwind utilities;

.title {
    @apply text-red-500 text-4xl font-mono text-center
}

.background {
    @apply bg-gray-600
}